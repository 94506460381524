import axios, { AxiosInstance } from "axios";
class ApiConfig {

    // ----------production------------
    // private baseURL = 'https://veedu.app';

    // ----------SabPaisa URL for production----------------
    // private sabpaisaURL = "https://securepay.sabpaisa.in/SabPaisa/sabPaisaInit?v=1"

    // ----------stage----------------
     private baseURL = 'https://dev.veedu.app';

    // ----------SabPaisa URL for stage and local----------------
    private sabpaisaURL = "https://stage-securepay.sabpaisa.in/SabPaisa/sabPaisaInit?v=1";

  //  private baseURL = 'http://localhost:9191';


    // private baseURL = 'http://192.168.1.9:9191';
    // private baseURL = 'http://192.168.0.127:9191';
    // private baseURL = 'http://192.168.0.144:9191';
    // private baseURL = 'http://192.168.0.136:9191';

    private apiBaseUrl: string;

    constructor() {
        this.apiBaseUrl = this.baseURL + '/api/';
    }

    private getApiBaseURL = () => {
        return this.apiBaseUrl;
    }

    public getBaseURL = () => {
        return this.baseURL;
    }

    public getSabpaisaURL = () => {
        return this.sabpaisaURL;
    }

    public getAxiosInstance = () => {
        return axios.create({ baseURL: this.getApiBaseURL() });
    }
}
export default ApiConfig;
